@import "../../style/variables.scss";

.heading {
	font-size: 32px;
	font-weight: 800;
	margin-top: 25px;
}

.version {
	color: $unactive-color;
	display: inline-block;
	padding-left: 0.5em;
	font-weight: normal;
	font-size: 24px;
}
