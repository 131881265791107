$pending-color: #ffc300;

//palette  here https://coolors.co/palette/fffcf2-ccc5b9-403d39-252422-eb5e28
$primary-color: #eb5e28;
$unactive-color: #ccc5b9;
$font-color: #252422;
$bg-color: #fffcf2;
$line-color: #403d39;

//palette here https://coolors.co/palette/05668d-427aa1-ebf2fa-679436-a5be00
$success-color: #679436;
