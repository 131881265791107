@import "../../style/variables.scss";

@mixin tablet-and-up {
	@media screen and (min-width: 769px) {
		@content;
	}
}
@mixin mobile-and-up {
	@media screen and (min-width: 601px) {
		@content;
	}
}
@mixin tablet-and-down {
	@media screen and (max-width: 768px) {
		@content;
	}
}
@mixin mobile-only {
	@media screen and (max-width: 600px) {
		@content;
	}
}

.order {
	position: relative;
	min-width: 93%;
	&-number {
		font-weight: 700;
	}
	&-address {
		color: #4f4f4f;
		font-family: sans-serif;
		line-height: 1.5;
		margin-top: 0.4rem;
		@include mobile-only {
			font-size: 0.9rem;
		}
	}
}

li {
	padding-bottom: 1.5rem;
	border-left: 1px dashed $unactive-color;
	position: relative;
	padding-left: 20px;
	margin-left: 10px;
	&:last-child {
		border: 0px;
	}
	&:before {
		content: "";
		width: 15px;
		height: 15px;
		background: white;
		border: 1px solid $unactive-color;
		box-shadow: 3px 3px 0px $unactive-color;
		box-shadow: 3px 3px 0px $unactive-color;
		border-radius: 50%;
		position: absolute;
		left: -10px;
		top: 0px;
	}
}
.time {
	color: #2a2839;
	font-weight: 700;
	@include mobile-and-up {
		font-size: 0.9rem;
	}
	@include mobile-only {
		margin-bottom: 0.3rem;
		font-size: 0.85rem;
	}
}
