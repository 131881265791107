@import "../../style/variables.scss";

.dashboard {
	padding: 16px 0;
}

.dashboard-item {
	display: inline-block;
	padding: 0.5em 1em;
	border-radius: 0.5em;
	border: $unactive-color 1px solid;
	font-weight: 400;
	font-size: 11px;
	margin: 0.5em 0.5em;
	&__value {
		font-weight: 700;
		font-size: 32px;
		text-align: center;
	}
}
